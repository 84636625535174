.boxwebsite {
    display: inline !important;
}

.boxmobile {
    display: none !important;
}


@media only screen and (max-width: 746px) {
    .boxwebsite {
        display: none !important;
    }

    .boxmobile {
        display: flex !important;
        justify-content: space-between;
        margin: 0px 0px 0px 0px !important;
    }

    .button_boxwhatapp {
        display: flex;
        flex-wrap: wrap !important;
        justify-content: space-around !important;
        background-color: #0375B0 !important;
        /* border-radius: 5px !important; */
        border-radius: 2px 5px 5px 2px !important;
        border: 1px solid #0375B0 !important;
        text-align: right;
        color: black;
        font-weight: 600;
        margin: 8px 9px 0px 0px !important;
        padding: 0px 12px 0px 10px !important;

        -webkit-border-radius: 40px;
        cursor: pointer;
        text-decoration: none;
        -webkit-animation: glowing 1300ms infinite;
        -moz-animation: glowing 1300ms infinite;
        -o-animation: glowing 1300ms infinite;
        animation: glowing 1300ms infinite;
    }

    @-webkit-keyframes glowing {
        0% {
            background-color: #0375B0;
            -webkit-box-shadow: 0 0 3px #0375B0;
        }

        50% {
            background-color: #0375B0;
            -webkit-box-shadow: 0 0 15px #0375B0;
        }

        100% {
            background-color: #0375B0;
            -webkit-box-shadow: 0 0 3px #0375B0;
        }
    }

    @-moz-keyframes glowing {
        0% {
            background-color: #0375B0;
            -moz-box-shadow: 0 0 3px #0375B0;
        }

        50% {
            background-color: #0375B0;
            -moz-box-shadow: 0 0 15px #0375B0;
        }

        100% {
            background-color: #0375B0;
            -moz-box-shadow: 0 0 3px #0375B0;
        }
    }

    @-o-keyframes glowing {
        0% {
            background-color: #0375B0;
            box-shadow: 0 0 3px #0375B0;
        }

        50% {
            background-color: #0375B0;
            box-shadow: 0 0 15px #0375B0;
        }

        100% {
            background-color: #0375B0;
            box-shadow: 0 0 3px #0375B0;
        }
    }

    @keyframes glowing {
        0% {
            background-color: #0375B0;
            box-shadow: 0 0 3px #0375B0;
        }

        50% {
            background-color: #0375B0;
            box-shadow: 0 0 15px #0375B0;
        }

        100% {
            background-color: #0375B0;
            box-shadow: 0 0 3px #0375B0;
        }
    }

}

.button_boxwhatapp img {
    position: absolute !important;
    padding: 0px 0px 0px 0px !important;
    margin: -1px 0px 0px 0px !important;
    width: 23px !important;
    height: 24px !important;
}

/* @media only screen and (max-width: 340px) {
    .button_boxwhatapp img {
        height: 18px !important;
    }
}

@media only screen and (max-width: 298px) {
    .button_boxwhatapp img {
        height: 14px !important;
    }
} */