.contact-address-container {
    /* margin-top: 30px; */
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.address-makr-container {
    display: flex;
    align-items: center;
}

.contact-data-icon {
    margin-right: 0.938rem;
    height: 1.563rem;
    width: 1.563rem;
}

.gmap-icon {
    height: 2.188rem;
    padding-left: 0.625rem;
}

.contact-data-container {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.phone-numbers-container {
    display: flex;
    word-break: break-all;
    flex-wrap: wrap;
}

.ph-number-link {
    color: black;
}

.phone-number-text {
    white-space: nowrap;
}

.social-icons-contact-us {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact-header {
    font-size: 20px !important;
    color: black;
    text-align: center;
}
