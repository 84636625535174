.label_buttons {
    border: 1px solid #0375b1;
    border-radius: 5px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 3px;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: 'Proxima-regular' !important;
    cursor: pointer;
}

.label_buttons_selected {
    border-radius: 5px;
    background-color: #0375b1;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    border-style: none;
    border: none;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding-top: 6px;
    padding-bottom: 3px;
    font-family: 'Proxima-regular' !important;
    cursor: pointer;
}

.proceed_btn {
    margin-top: 30px;
    background-color: #0375b1;
    color: white;
    border-radius: 5px;
    padding-right: 45px;
    padding-left: 45px;
    border-style: none;
    border: none;
    padding-bottom: 5px;
    padding-top: 7px;
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: 'Proxima-regular' !important;
}

.proceed_btn_disabled {
    margin-top: 30px;
    background-color: grey;
    color: white;
    border-radius: 5px;
    padding-right: 45px;
    padding-left: 45px;
    border-style: none;
    border: none;
    padding-bottom: 7px;
    padding-top: 7px;
    font-size: 19px !important;
    font-weight: 500 !important;
}

.formatted_address {
    font-size: 16px !important;
    font-weight: 300 !important;
    font-family: 'Proxima-regular' !important;
}

.address_input {
    font-size: 17px !important;
}

@media only screen and (min-width: 576px) {
    .address_container {
        max-width: 410px;
    }
}

