.map_container {
  width: 100%;
  position: relative;
}

.selectdelivery_text {
  font-size: 19px !important;
  font-weight: 600 !important;
  color: #7e7e7e;
  font-family: 'Proxima-regular' !important;
}

.formattedaddress_text {
  padding-left: 20px;
  font-weight: 600 !important;
  font-weight: 400 !important;
  font-size: 19px !important;
  min-height: 40px;
  font-family: 'Proxima-regular' !important;
}

.confirm_btn {
  font-size: 20px !important;
  font-family: 'Proxima-regular' !important;
}

.bold_head {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #0375b1;
  font-family: 'Proxima-regular' !important;
  margin-bottom: 5px;
}


.gsearch_input {
  height: 40px;
  border: 1px solid#0375b1;
  border-radius: 3px;
  font-weight: 300 !important;
  font-size: 17px !important;
  font-family: 'Proxima-regular' !important;
  padding-top: 5px;
}

.search_icon_address {
  position: absolute;
  right: 0;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.search_list {
  font-weight: 400 !important;
  border-bottom: 1px solid #ececec;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px !important;
  font-family: 'Proxima-regular' !important;
  font-weight: 300 !important;
  cursor: pointer;
}

.start-element {
  flex: 0 0 auto;
}

.center-element {
  flex: 0 0 auto;
  margin: 0 auto;
}

.current_location_btn {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.change_text {
  font-size: 17px !important;
  font-family: 'Proxima-regular' !important;
}

.gsearch_input::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Proxima-regular' !important;
}

.map_pin_textfirst {
  color: white;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  font-family: 'Proxima-regular' !important;
  font-size: 15px !important;
}

.map_pin_textsecond {
  color: white;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  font-family: 'Proxima-regular' !important;
  font-weight: 300;
  font-size: 14px !important;
  margin-top: 5px;
}

.map_bottom_address_container {
  min-height: 220px;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top: 1px solid lightgrey;
}

@media only screen and (min-width: 576px) {
  .map_bottom_address_container {
    transform: translateX(-50%);
    left: 50%;
    max-width: 410px;
  }

  .map_container {
    max-width: 410px;
    position: relative;
  }
}