.footer {
	display: flex;
	flex-direction: column;
	background-color: #125d8d;
	height: auto;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 2%;
	padding-bottom: 2%;
	color: white;

}

.chattybao_logo {
	margin-left: -10px;
}

.bottom_container {
	display: flex;
	justify-content: space-between;
	margin-top: 1.5%;
}

.links_container {
	display: flex;
	justify-content: space-between;
}

.social_icon {
	height: 40px;
	width: 40px;
}

.left_links {
	margin-right: 20px;
}

.right_links {
	margin-right: 30%;
}

@media only screen and (max-width: 480px) {
	.bottom_container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		margin-top: 1.5%;
		align-items: center;
		text-align: center;
	}

	.policy_container {
		text-align: center;
	}

	.chattybao_logo {
		align-self: center;
		margin-left: 0;
	}

	.social_icon {
		width: 30px;
		height: 30px;
	}

	.footer_text {
		font-family: 'Barlow-regular' !important;
	}

	.links_container {
		display: block;
		text-align: center;
	}

	.right_links {
		margin-right: 0;
	}

	.left_links {
		margin-right: 0;
	}
}

@media only screen and (min-width: 480px) and (max-width: 780px) {
	.copyright_text {
		max-width: 50%;
	}
}


// 	&__container {
// 		padding: 80px;
// 		text-align: center;

// 		h5 {
// 			font-weight: 300;
// 			padding-bottom: 10px;
// 			color: #fff;
// 			padding-right: 10px;
// 			cursor: pointer;
// 			font-size: 24px;
// 		}

// 		h6 {
// 			font-weight: 300;
// 			padding-bottom: 10px;
// 			color: #fff;
// 			padding-right: 10px;
// 			cursor: pointer;
// 			font-size: 22px;
// 		}
// 	}

// 	&__heading {
// 		display: flex;
// 		justify-content: center;
// 	}
// }

// @media screen and (min-width: 320px) and (max-width: 760px) {
// 	.footer {
// 		&__heading {
// 			flex-direction: column;
// 			padding-bottom: 30px;
// 		}

// 		&__line {
// 			display: none;
// 		}

// 		&__container {
// 			padding: 60px 20px;
// 			text-align: left;

// 			h5 {
// 				margin: 5px 0;
// 				padding-bottom: 10px;
// 				font-size: 20px;
// 			}

// 			h6 {
// 				font-size: 18px;
// 				margin: 15px 0;
// 			}
// 		}
// 	}
// }
