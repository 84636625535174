.user {
	background-color: #e98424;
	color: #fff;
	padding: 80px;
	border-radius: 80px 80px 0 0;
	text-align: center;
	&__content1 {
		background-color: #fff;
		color: #424143;
		padding: 40px 60px;
		border-radius: 80px 80px 0 0;
		h6 {
			padding-top: 20px;
			color: #424143;
			font-size: 22px;
		}
		p {
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.col {
		padding: 0;
	}
	.user {
		padding: 40px 0px;
		border-radius: 30px 30px 0 0;
		&__heading {
			h1 {
				font-size: 24px;
				font-weight: 600;
			}
		}
		&__content1 {
			padding: 40px 20px;
			border-radius: 0 0 40px 40px;
			p {
				text-align: justify;
			}
			h6 {
				padding-top: 15px;
			}
		}
	}
}
