.business_first_container {
	background-color: #def4ff;
	height: 540px;
	margin-top: 200px;
	margin-bottom: 200px;
	border-radius: 40px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.business_box_container {
	position: absolute;
	left: 5%;
	top: 12%;
	display: flex;
	justify-content: center;
  	align-items: center;
}

.business_ads_container {
	background-color: #fff7f0;
	margin-bottom: 80px;
	border-radius: 40px;
	// padding-left: 10%;
	// padding-right: 10%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.business_ad_container {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.business_ad_container2 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.max_width {
	max-width: 500px;
}

.business_ad_image {
	width: 27%;
}

.business_ad_image2 {
	width: 20%;
}

.business_box_container_text {
	font-size: 40px;
	color: black;
	line-height: normal;
	font-weight: 700;
	text-align: center;
	font-family: 'Barlow-bold';
}

.third_container_business {
	background-color: #def4ff;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-bottom: 90px;
	border-radius: 40px;
	padding-left: 10%;
	padding-right: 10%;
}

@media only screen and (min-width: 1297px) and (max-width: 1429px) {
	.big_box_business {
		width: 80%;
	}

	.business_box_container {
		position: absolute;
		left: 0;
		top: 12%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (min-width: 1129px) and (max-width: 1297px) {
	.big_box_business {
		width: 70%;
	}

	.business_box_container {
		position: absolute;
		left: -5%;
		top: 12%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (min-width: 887px) and (max-width: 1129px) {
	.big_box_business {
		width: 60%;
	}

	.business_box_container {
		position: absolute;
		left: -10%;
		top: 12%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.business_box_container_text {
		font-size: 25px;
		color: black;
		line-height: normal;
		font-weight: 700;
		text-align: center;
	}
}

@media only screen and (max-width: 1129px) {
	.big_box_business {
		content: url("../../../assets/business_textbox_mobile.png") !important;
		flex: 1
	}

	.business_box_container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		width: 1fr;
		margin-top: -40px;
	}

	.business_box_container_text {
		font-size: 29px !important;
		font-family: 'Barlow-bold' !important;
	}
}


@media only screen and (min-width: 480px) and (max-width: 1024px) {
	.business_ads_container {
		background-color: #fff7f0;
		margin-top: 80px;
		margin-bottom: 100px;
		border-radius: 40px;
		padding-left: 10%;
		padding-right: 10%;
	}

	.third_container_business {
		background-color: #def4ff;
		padding-top: 50px;
		padding-bottom: 50px;
		margin-bottom: 80px;
		border-radius: 40px;
		padding-left: 10%;
		padding-right: 10%;
	}

	// .business_box_container {
	// 	position: absolute;
	// 	left: 10%;
	// 	bottom: 10%;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }

	.max_width {
		max-width: 200px;
	}

	.business_ad_image {
		width: 40%;
	}

	.business_ad_image2 {
		width: 40%;
	}

	// .business_box_container_text {
	// 	font-size: 20px;
	// 	color: white;
	// 	line-height: normal;
	// 	font-weight: 700;
	// 	text-align: center;
	// }
}

@media only screen and (max-width: 480px) {
	.business_ad_container {
		flex-direction: column;
	}

	.big_box_business {
		content: url("../../../assets/business_textbox_mobile.png") !important;
		flex: 1
	}

	.business_cover_image {
		content: url("../../../assets/business_page_cover_mobile.jpg") !important;
	}

	.business_box_container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		width: 1fr;
		margin-top: -40px;
	}

	.business_ad_container2 {
		flex-direction: column-reverse;
	}

	.business_ads_container {
		margin-top: 40px;
		margin-bottom: 40px;
		border-radius: 10px;
		padding-left: 2%;
		padding-right: 2%;
		padding-top: 40px;
	}

	.text_align {
		text-align: center;
	}

	.business_ad_image {
		width: 90%;
	}

	.business_ad_image2 {
		width: 60%;
	}
	
	.business_box_container_text {
		font-size: 29px !important;
		font-family: 'Barlow-bold' !important;
	}

	.third_container_business {
		background-color: #def4ff;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-bottom: 40px;
		border-radius: 10px;
		padding-left: 10%;
		padding-right: 10%;
	}

	.margin_less {
		margin-bottom: 0 !important;
	}
	
}