.button_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f3f3f4;
    height: 50px;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: absolute;
    bottom: -2%

}

.button {
    color: #3a3a3c;
    font-size: 16px !important;
    font-family: "Proxima-regular" !important;
}

.merchant_name {
    color: #939598;
    font-weight: 600 !important;
    font-size: 13px !important;
}

.hor_divider {
    height: 25px;
    border: 1px solid #939598;
    opacity: 0.5;
}

.pd_details {
    margin-left: 15px;
}

.pd_name {
    color: #3a3a3c;
    font-size: 21px !important;
    font-family: 'Proxima-regular' !important;
}

.old_price {
    color: #939598 !important;
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 17px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 500 !important;
}

.discount_price {
    font-size: 20px !important;
    margin-right: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 500 !important;
    color: #3a3a3c !important;
}

.unit {
    color: #939598 !important;
    font-family: 'Proxima-regular' !important;
    /* padding-top: 5px; */
    margin-bottom: 12px;
    font-size: 14px !important;
}

.desc_heading {
    margin-top: 20px;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Proxima-regular' !important;
    margin-bottom: 0 !important;
    color: #3a3a3c;
}

.desc_text {
    margin-top: 0 !important;
    margin-bottom: 20px;
    color: #939598;
    font-family: 'Proxima-regular' !important;
    font-size: 13px !important;
    text-align: justify;
    padding-right: 15px;
}

.ofr_text {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    text-align: center;
    margin-left: 5%;
    font-family: 'Proxima-regular' !important;
    font-size: 19px !important;
    color: white;
    font-weight: 600 !important;
}