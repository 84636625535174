.whole-contaier {
    padding-left: 1.875rem;
}

.tracking-container {
    padding-top: 3.125rem;
    text-align: center;
}

.circle-marker {
    border-radius: 50%;
    background-color: #9a70b0;
    height: 1.5rem;
    width: 1.5rem;
}

.circle-marker-empty {
    border-radius: 50%;
    border: 1px solid #9a70b0;
    height: 1.5rem;
    width: 1.5rem;
    opacity: 0.5;
}

.status-text-header {
    margin: 0;
    font-size: 1rem;
    margin-left: 0.625rem;
}

.status-text {
    margin: 0;
    font-size: 0.8rem;
    margin-left: 0.625rem;
}

.vertical-line-container {
    width: 1.5rem;
    display: flex;
    justify-content: center;
}

.vertical-line-indicator {
    height: 7rem;
    border: 1px solid #9a70b0;
    width: 0;
}

.vertical-line-indicator-black {
    height: 7rem;
    border: 1px solid #9a70b0;
    width: 0;
    opacity: 0.2;
}

.status-container {
    display: flex;
    align-items: center;
}

.track-text {
    font-size: 25px !important;
}

.inactive-text {
    text-align: center;
}