.App {
	font-family: "Proxima-regular";
	color: white;

}

* {
	font-family: "Proxima-regular" !important;
	font-weight: 600;
	overflow: -moz-hidden-unscrollable !important;
}

.non-clickable {
	pointer-events: none;
}

hr {
	border-color: rgb(0 0 0 / 8%) !important;
}

li {
	list-style-type: none;
}

.carousel .thumbs {
	display: none !important;
}

.carousel .carousel-status {
	display: none !important;
}

.carousel.carousel-slider .control-arrow {
	top: 0;
	color: rgb(255, 0, 0) !important;
	font-size: 26px !important;
	bottom: 0 !important;
	margin-top: 0 !important;
	padding: 5px !important;
	background-color: #000000 !important;
	z-index: 1 !important;
}
.carousel.carousel-slider .control-arrow:hover {
	background-color: #000000 !important;
	opacity: 0.4 !important;
}

.carousel .control-dots {
	/* display: none !important; */
}
.dot {
	position: relative;
	top: 0px;
	font-size: 32px;
	left: 0px;
}

/* .slick-dots li button {
	pointer-events: none;
	width: 100%;
	height: 100%;
	padding: 0;
}
.slick-dots li button:before,
.slick-dots li.slick-active button:before {
	width: 100%;
	height: 100%;
	font-size: 8px;
	box-shadow: 1px 1px 2px rgb(0 0 0 /90%);
	transition: opacity 0.25s ease-in;
	color: transparent;
	position: relative;
	border-radius: 50%;
	opacity: 1;
}



.slick-dots {
	margin: 0px auto;

	overflow: hidden;
	white-space: nowrap;
	width: 15% !important;

	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	white-space: nowrap;
}
.carousel .slide img {
	width: 100% !important;
}
.slick-dots {
	bottom: 20px;
}
.slick-dots li {
	margin: 0px !important;
	padding: 2px !important;
} */

/* .carousel .carousel-left-arrow {
	left: 39px;
	color: rgb(0, 255, 229);
  } */

/* .section {
	padding: 1rem !important;
	overflow-y: auto !important;
	direction: ltr !important;
	scrollbar-color: #d4aa70 #e4e4e4 !important;
	scrollbar-width: thin !important;
  }
  
  .section::-webkit-scrollbar {
	width: 20px !important;
  }
  
  .section::-webkit-scrollbar-track {
	background-color: #e4e4e4 !important;
	border-radius: 100px !important;
  }
  
  .section::-webkit-scrollbar-thumb {
	border-radius: 100px !important;
	background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%) !important;
	box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
  } */

#scrollbar-wrapper {
	/* display: flex; */
	height: 100vh;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 0px 4px 4px 4px;
}
.react-transform-wrapper,
.react-transform-component {
	width: 100% !important;
}

.slick-arrow {
	display: none !important;
}

/* .slick-slide img {
	width: 100% !important;
} */

.scrollbar {
	height: 100%;
	width: 100%;
	background: #ffffff;
	overflow-y: scroll;
	margin: 0px;
	padding: 0px;
}

.force-overflow {
	min-height: auto;
}

#style-15::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	border-radius: 10px;
}

#style-15::-webkit-scrollbar {
	width: 10px;
	background-color: #ffffff;
}

#style-15::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ffffff;
	background-image: -webkit-gradient(
		linear,
		40% 0%,
		75% 84%,
		from(#0375b0),
		to(#0375b0),
		color-stop(0.6, #0375b0)
	);
}

.bloc-tabs {
	display: flex !important;
	margin: 20px 0px 0px 0px;
	justify-content: space-around;
}

.bloc-tabs1 {
	display: flex !important;
	margin: 0px 0px 0px 0px;
	justify-content: space-around;
}

.bloc-tabs button {
	color: rgb(0, 0, 0) !important;
	border: none !important;
}

.active-tabs1 {
	background: #ffffff !important;
	color: #0375b0 !important;
	/* text-decoration: underline 3px #2ca74a !important;
	text-underline-offset: 0.4em !important; */
	font-size: 14px !important;
	border-bottom: 3px solid #2ca74a !important;
}

.tabs1 {
	/* padding: 15px !important; */
	/* text-align: center !important; */
	/* width: 50% !important; */
	background: #ffffff !important;
	color: rgb(0, 0, 0) !important;
	cursor: pointer !important;
	font-size: 14px !important;
	/* box-sizing: content-box !important;
	position: relative !important; */
	/* outline: none !important; */
}

.carouselwindow {
	display: contents !important;
}

.carouselmobile {
	display: none !important;
}

.grid_contents {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin: 40px;
}

.shop_details_container {
	position: absolute;
	/* height: auto; */
	width: 93%;
	height: 15.625 rem;
	border-radius: 20px;
	background-color: #FFFFFF;
	transform: translate(-50%, -50%);
	top: 170px;
	left: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 15px;
	z-index: 2;
	padding-right: 0.7rem;
	/* font-size: 16px; */
}

.shop_details_container_2lines {
	position: absolute;
	/* height: auto; */
	width: 85%;
	border-radius: 20px;
	background-color: #FFFFFF;
	transform: translate(-50%, -50%);
	top: 60%;
	left: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 40px;
	z-index: 2;
	/* font-size: 16px; */
}

.search_input::placeholder {
	color: #939598;
	font-size: 16px !important;
	font-weight: 400 !important;
}

.slick-dots li button:before {
	margin-top: -40px !important;
	color: white;
	box-shadow: none;
	opacity: 1;
	font-size: 10px;
  }

  .slick-dots li.slick-active button:before {
	color: green;
	opacity: 1;
  }



.shop_photo_BG {
	position: relative;
	width: 90px;
	height: 90px;
	border-radius: 10px;
}

.shop_photo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60px;
}

.shop_name {
	font-size: 1.3rem !important;
	margin-bottom: 0 !important;
	color: #434345;
	/*  */
	font-weight: 500 !important;
	margin-top: 0 !important;
	text-align: start;
	-webkit-line-clamp: 2 !important;
    overflow: hidden;
	display: -webkit-box;
    -webkit-box-orient: vertical;
	line-height: normal !important;
	overflow-wrap: break-word;
}

.button_text {
	color: #434345 !important;
	font-size: 16px !important;
}

.fixed {
	position: fixed;
	top: 10%;
	left: 0;
	right: 0;
	z-index: 10;
  }

.non_heading {
	font-size: 18px !important;
	margin-bottom: 5px !important;
	margin-top: 0 !important;
	color: #9c9ea1;
	
	font-weight: 500 !important;
	overflow-wrap: break-word;
	text-align: center;
}

.non_heading_for_l4 {
	font-size: 17px !important;
	margin-bottom: 5px !important;
	margin-top: 0 !important;
	color: #9c9ea1;
	
	font-weight: 500 !important;
	overflow-wrap: break-word;
	text-align: start;
	margin-left: 10px;
}

.divider {
	border-right: 1px solid #bebfc1;
	height: 25px;
}

.background_image {
	height: 280px;
}

.background_image_two {
	height: 280px;
}

.search_bar {
	position: relative;
	width: 80%;
	margin-right: 3%;
	/* bottom: 13px;
	right: 0;
	left: 0;
	top: auto;
	display: flex;
	justify-content: center;
	align-items: center; */
}

.non_fixed_search {
	height: 35px;
	border-radius: 10px;
	border-style: none;
	padding-left: 35px;
	width: 100%;
	border: none;
	font-weight: 200;
}

.fixed_search {
	height: 45px;
	border-radius: 10px;
	padding-left: 50px;
	padding-right: 40px;
	width: 100%;
	border: 1px solid #125d8d;
}

.search_bar.fixed {
	position: sticky !important;
	top: 0;
	width: 100%;
}
  
/* .sticky {
	position: fixed;
	top: 0;
} */

.non_heading2 {
	font-size: 14px !important;
	margin-bottom: 0 !important;
	margin-left: 5px !important;
	margin-top: 3px !important;
	color: #9c9ea1;
	
	font-weight: 500 !important;
}

.search_input {
	all: unset;
}

.main_cards {
	max-width: 100% !important;
	width: 100% !important;
	height: fit-content !important;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
}

.card_imgs {
	padding: 10px !important;
	display: flex;
	justify-content: space-around;
	height: 120px !important;
	max-width: 18% !important;
	border-radius: 10px;
	border: 1px solid #eadede;
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}

.searchbar {
	display: none !important;
}

.box_header {
	display: inline !important;
	background-color: #ffffff !important;
}

.box_header_content {
	display: none !important;
}

.maincards_range {
	text-align: left !important;
	color: #000000 !important;
	font-weight: 400 !important;
	font-size: 15px !important;
	margin: 8px 5px 8px 5px !important;
}

.crossbtn {
	display: flex;
	justify-content: flex-end;
	color: #000000 !important;
	font-size: 20px !important;
}

.cross_btn {
	top: 0px;
	right: 0px;
	display: flex;
	justify-content: flex-end;
	color: #000000 !important;
	font-size: 14px !important;
}

/* .css-ypiqx9-MuiDialogContent-root {
	/* background-color: #000000 !important; */
/* padding: 0px !important;
	margin: 0px !important;
	width: 100% !important;
	max-width: 100% !important; */
/* height: 100% !important;
	display: flex; */
/* align-items: center;
	justify-content: center; */
/* }  */
.dialogBox {
	padding: 0px !important;
	margin: 0px !important;
	width: 100% !important;
	max-width: 100% !important;
	height: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.poweredby_text {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;

}

/* .powerdby_text2 */

.shop_photo_cl {
	position: absolute;
	bottom: 250px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
}

.rating_like_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 5px;
}

.cart_icon {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cart_count_container {
	position: absolute;
	top: -10%;
	right: -10%;
	background-color: #ef0500;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.3rem;
	height: 1.3rem;
	z-index: 101;
}

.cart_count {
	margin: 0;
	line-height: normal !important;
	font-size: 0.91rem !important;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
	font-weight: 400;
	color: white;
}

.share_icon {
	width: 1rem;
	align-self: flex-end;
}

.message_to_whatsapp {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 0.5rem;
	height: 2.7rem;
	width: 85%;
	border: 1px solid #2076a3;
}

.message_to_whatsapp_input {
	all: unset;
	align-self: flex-start;
	border: none;
	width: 80%;
	padding-left: 0.7rem;
	margin-top:8px;
	
}

.chat_icon {
	width: 1.5rem;
	height: 1.5rem;
}

.click_to_order_text {
	color: #2076a3;
	margin-left: 1rem;
	
	line-height: normal !important;
	font-size: 1.145rem !important;
}

.send_icon {
	height: 1.5rem;
	margin-right: 0.5rem;
	align-self: center;
}

.msg_call_container {
	margin-left: 0.988rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.call_container {
	/* border: 1px solid #e9e9ea; */
	padding: 0.695rem;
	border-radius: 0.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border: 1px solid #2076a3;
}

.top-bar {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 4.5rem;
	z-index: 999;
}

.tab-green-bg {
	width: 100%;
	position: absolute;
	height: 4.5rem;
	z-index: -10;
	object-fit: cover;
	/* object-position: bottom; */
}

.green-bg-shopname {
	width: 100%;
	/* height: 10rem; */
	z-index: -10;
	object-fit: cover;
	/* object-position: top; */
}

.top-bar-right-icons-container {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	gap: 20%;
	padding-right: 3%;
}

.toggle-search-container {
	display: flex;
	flex: 2;
	gap: 20px;
	align-items: center;
	padding-left: 3%;
}

.menu-toggle {
	width: 1.3rem;
	height: 1.3rem;
	z-index: 5;
}

.top-bar-right-icons {
	width: 1.5rem;
	height: 1.5rem;
}

.menu-bar-close-icon {
	position: absolute;
	right: 3%;
	top: 2%;
	width: 0.938rem;
	z-index: 100;
}

.menu-bar-content {
	padding: 3.125rem 1.875rem;
}

.menu-bar-route {
	font-size: 1.5rem !important;
	
}

.menu-bar-routes-container {
	padding-bottom: 3.125rem;
	border-bottom: 2px solid black;
}

.first-image-content {
	position: absolute;
	top: 10%;
	left: 10%;
	width: 80%;
}

.first-image-shop-name {
	color: white;
	font-size: 26px !important;
	font-family: 'Bahnschrift' !important;
	line-height: 45px !important;
	margin-bottom: 0;
}

.shop-name-no-description {
	color: white;
	font-size: 26px !important;
	font-family: 'Bahnschrift' !important;
	line-height: 45px !important;
}

.shop-now-btn {
	background-color: #009345;
	color: white;
	padding: 0.313rem 1.188rem;
	font-size: 20px !important;
	border-radius: 0.625rem;
	text-align: center;
	width: 10rem;
}

.carousel-image {
	width: 100%;
	object-fit: cover;
}

.first-image-container {
	position: relative;
}

.powered-by-chattybao {
	position: absolute;
	top: -5px;
	right: -5px;
	height: 45px;
}

.email-text {
	font-weight: 300 !important;
	font-size: 1rem !important;
}

.social-icon-text-container {
	margin-top: 5px;
	display: flex;
	gap: 0.938rem;
	align-items: center;
}

.call-icon-text-container {
	width: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
	height: 25px;
	margin-bottom: 10px;
}

.phonenumbers-container {
	display: flex;
	align-items: center;
}

.call-icon {
	width: 0.938rem;
    height: 0.938rem;
}

.contact-social-icon {
    width: 1.763rem;
    height: 1.763rem;
	border-radius: 5px;
}

.phone-number-text-menu {
    font-size: 1rem !important;
	white-space: nowrap;
	font-weight: 400;
}

.contact-heading {
    font-size: 1.55rem !important;
}

.contact-merchant-name {
    font-size: 1rem !important;
}

.address-text-contact {
    font-size: 1rem !important;
    font-weight: 200 !important;
}

.contact-social-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.call-contact-us-header {
	margin-top: 30px;
    margin-left: 15px;
    font-size: 20px !important;
    font-family: 'Proxima-regular' !important;
	color: #0375b0;
	letter-spacing: 2px;
}

.call-contact-container {
	padding-left: 15px;
	padding-right: 15px;
}

.phone_call_text {
	font-size: 18px !important;
	font-weight: 400;
	font-family: "Proxima-regular" !important;
	color: #686868;
}

.phone-owner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.business-owner-text {
	font-size: 16px !important;
	font-weight: 400;
	font-family: "Proxima-regular" !important;
	color: grey;
}

.phone-number-divider {
	display: block !important;
    background-color: grey;
    opacity: 0.2;
}

.banner-container {
	padding-bottom: 20px;
	padding-top: 15px;
	padding-left: 10px;
	padding-right: 10px;
}

.shopname-greenbg-container {
	position: relative;
	margin-top: -5px;
}

.shop-name-container {
	position: absolute;
	top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
}

.powered-by-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	
}

.powered-by-div {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	right: 0;
	gap: 3px;
	color: white;
	background-color: #9bb0b3;
	border-top-left-radius: 15px;
	padding-top: 3px;
	padding-right: 10px;
	padding-left: 10px;
	/* padding: 3px 10px; */
	opacity: 0.8;
	padding-bottom: 2px;
}

.powered-by-text {
	font-family: 'proxima-regular' !important;
	font-size: 8px !important;
	margin: 0;
	margin-top: 2px;
}

.chattybao-text {
	font-family: 'proxima-regular' !important;
	font-size: 11px !important;
	margin: 0;
}

.merchant-about-us {
	color: white;
	font-size: 13px !important;
	margin: 0;
	max-width: 88%;
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
	font-weight: 200;
}

.shop-name-divider {
	border: 1px solid white;
	width: 70px;
	margin-bottom: 10px;
}

.shop-name-divider-black {
	border: 1px solid #414042;
	width: 70px;
	margin-bottom: 10px;
}

.about-us-header {
	font-size: 18px !important;
}

.about-us-content {
	font-weight: 200;
}

.about-us-container {
	display: flex;
	padding-bottom: 40px;
	align-items: center;
}

.black-font {
	color: #414042;
}

.bottom-margin {
	margin-bottom: 50px;
}

@media only screen and (max-width: 360px) {

	.top-bar-right-icons {
		width: 1.3rem;
		height: 1.3rem;
	}

	.top-bar-right-icons-container {
		gap: 15%;
	}

	.cart_count_container {
		position: absolute;
		top: -10%;
		right: -10%;
		background-color: #ef0500;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.1rem;
		height: 1.1rem;
		z-index: 101;
	}

	.cart_count {
		margin: 0;
		line-height: normal !important;
		font-size: 0.7rem !important;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
		font-weight: 400;
		color: white;
	}

	.carousel_image {
		width: 180px !important;
		height: 180px;
	}

	.chat_to_order_btn {
		padding-left: 2% !important;
		padding-right: 2% !important;
		margin-right: 5px !important;
	}

	.chat_to_order_text {
		font-size: 16px !important;
		
		margin-top: 11px;
	}

	.shop_image {
		width: 100px !important;
		height: 100px !important;
	}

	.shop_closed_image {
		height: 100px !important;
	}

}

@media only screen and (max-width: 332px) {
	.shop_image {
		width: 90px !important;
		height: 90px !important;
	}

	.shop_closed_image {
		height: 90px !important;

	}
}

@media only screen and (max-width: 576px) {
	* {
		font-size: 14px !important;
		font-family: "Proxima-regular" !important;
		font-weight: 600;
		overflow: -moz-hidden-unscrollable !important;
	}

	.shop_name_arrow {
		height: 5px;
		width: 18px;
		padding-left: 5px;
		margin-top: 9px;
	}

	.shopname_container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 0.2rem;
	}

	.chat_to_order_btn {
		display: flex;
		color: #125d8d;
		align-items: center;
		justify-content: center;
		padding-left: 5%;
		padding-right: 5%;
		height: 35px;
		border-radius: 10px;
		border: 1px solid #e9e9ea;
		margin-right: 10px;
	}

	.chat_to_order_text {
		font-size: 16px !important;
		
		margin-top: 11px;
	}

	.close_btn {
		position: absolute;
		right: 3%;
		top: 2%;
		width: 17px;
		z-index: 100;
	}

	.green_dot {
		width: 4px;
		height: 4px;
		background-color: #000000;
		border-radius: 50%;
		
	}

	/* .slick-slider {
		height: 200px !important;
	} */

	.text_inside_businessdetail {
		
		font-weight: 400 !important;
	}

	.box_header {
		display: inline !important;
		background-color: #ffffff !important;
	}

	.main_cards {
		max-width: 100% !important;
		width: 100% !important;
		height: fit-content !important;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	
	.card_imgs {
		padding: 10px !important;
		display: flex;
		justify-content: space-around;
		height: 120px !important;
		max-width: 18% !important;
		border-radius: 10px;
		border: 1px solid #eadede;
		border-bottom-right-radius: 6px;
		border-top-right-radius: 6px;
	}

	/* .fixed {
		position: fixed;
		top: 10%;
		left: 0;
		right: 0;
		z-index: 10;
	  } */
	
	.non_heading {
		font-size: 18px !important;
		margin-bottom: 5px !important;
		margin-top: 0 !important;
		color: #9c9ea1;
		
		font-weight: 500 !important;
	}

	.business_detail_header {
		font-size: 18px !important;
		margin-bottom: 5px !important;
		margin-top: 0 !important;
		color: #125d8d;
		
		font-weight: 400 !important;
	}
	
	.divider {
		border-right: 1px solid #bebfc1;
		height: 25px;
	}
	
	.search_bar {
		/* position: absolute;
		bottom: 13px;
		right: 0;
		left: 0;
		top: auto;
		display: flex;
		justify-content: center;
		align-items: center; */
	}
	
	/* .search_bar.fixed {
		position: fixed !important;
	} */
	  
	/* .sticky {
		position: fixed;
		top: 0;
	} */
	

	
	.search_input::placeholder {
		
		color: #939598;
		font-size: 16px !important;
		font-weight: 400 !important;
	}
	
	
	
	
	.shop_photo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60px;
	}
	

	.button_text {
		color: #434345 !important;
		font-size: 16px !important;
		
	}

	.fssai {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 65 !important;
	}

	.license_no {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px !important;
		color: #000000 !important;
		font-weight: 400 !important;
	}

	.cross_btn {
		display: flex !important;
		justify-content: flex-end !important;
		float: right !important;
		font-size: 16px !important;
		margin: 4px 5px 4px 0px !important;
	}

	.scrollbar {
		height: 100% !important;
		width: 100% !important;
		background: #ffffff !important;
		overflow-y: scroll !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.tabs1 {
		background: #ffffff !important;
		color: rgb(0, 0, 0) !important;
		cursor: pointer !important;
	}

	.bloc-tabs Typography {
		font-size: 12px;
		font-family: "Source Sans Pro";
		font-weight: 600;
	}

	.MuiTypography-noWrap {
		overflow: visible !important;
	}

	.carouselmobile {
		display: contents !important;
	}

	.carouselwindow {
		display: none !important;
	}

	.box_header_content {
		display: flex !important;
		justify-content: space-between !important;
		margin: 10px 0px 0px 0px !important;
	}

	.searchbar {
		display: inline !important;
		width: 140px !important;
		max-width: 140px !important;
	}

	.MuiPaper-elevation4 {
		box-shadow: none !important;
	}

	.maincards_typo {
		text-align: left !important;
		color: #000000 !important;
		font-weight: 400 !important;
	}

	.maincards_typodiv {
		display: grid;
		grid-template-columns: 0.4fr 1fr;
		gap: 1px;
		text-align: left !important;
		color: #000000 !important;
		font-weight: 600 !important;
		font-size: 14px !important;
		margin: 5px 0px 5px 0px !important;
	}

	.maincards_range {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1px;
		text-align: left !important;
		color: #000000 !important;
		font-weight: 400 !important;
		font-size: 15px !important;
		margin: 5px 0px 0px 0px !important;
	}

	.button_rating {
		display: flex;
		flex-wrap: wrap !important;
		justify-content: space-around !important;
		border-radius: 5px !important;
		text-align: right;
		color: #000000;
		font-weight: 300 !important;
		font-size: 5px !important;
	}

	/* .carousel .carousel-dots button {
	  font-size: 14px !important;
	} */
}

.button_rating img {
	width: 11px !important;
	height: 14px !important;
}

/* @media only screen and (max-width: 340px) {
	* {
	  font-size: 10px !important;
	}
  
	.button_rating img {
	  width: 7px !important;
	  height: 10px !important;
	}
  }
  
  @media only screen and (max-width: 298px) {
	* {
	  font-size: 7px !important;
	}
  
	.button_rating img {
	  width: 5px !important;
	  height: 8px !important;
	}
  } */
.dialogCarousel {
	padding: 0px !important;
	display: "flex" !important;
	align-items: "center" !important;
}
.catlogCount {
	/* color: #bdb9b9; */
	color:#218bd8;
	padding-left: 3px;
	margin-top: 10px;
	/* background-color: #ADD8E6 !important; */
	height:30px;
	padding-top: 2px;
}

.search_icon {
	left: 20px;
	color: #0375b1;
  	height: 20px;
  	position: absolute;
  	top: 50%;
  	transform: translate(-50%, -50%);
}

.cross-btn-visible {
	display: block;
  	color: #0375b1;
  	height: 15px;
  	position: absolute;
  	right: 1%;
  	top: 50%;
  	transform: translate(-50%, -50%);
}

.cross-btn-invisible {
	display: none;
  	color: #0375b1;
  	height: 15px;
  	position: absolute;
  	right: 1%;
  	top: 50%;
  	transform: translate(-50%, -50%);

}

.call_close_btn {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	z-index: 100;
}

.download-banner-close {
	position: absolute;
	right: 5px;
	top: 0;
	width: 20px;
	z-index: 100;
}

@media only screen and (min-width: 576px) {
	.main_container {
		display: flex;
		justify-content: center;
	}

	.data_container {
		width: 410px;
		position: relative;
	}

	.shopname_container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 0.2rem;
	}

	.shop_name {
		font-size: 17px !important;
		margin-bottom: 0 !important;
		color: #434345;
		
		font-weight: 500 !important;
		margin-top: 0 !important;
		text-align: start;
		-webkit-line-clamp: 2 !important;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		line-height: normal !important;
		overflow-wrap: break-word;
	}

	.shop_name_arrow {
		height: 5px;
		width: 18px;
		padding-left: 5px;
		margin-top: 9px !important;
	}

	.chat_to_order_btn {
		display: flex;
		color: #125d8d;
		align-items: center;
		justify-content: center;
		padding-left: 5%;
		padding-right: 5%;
		height: 35px;
		border-radius: 10px;
		border: 1px solid #e9e9ea;
		margin-right: 10px;
	}

	.fixed_search {
		height: 45px;
		border-radius: 10px;
		padding-left: 50px;
		padding-right: 40px;
		width: 100%;
		border: 1px solid #125d8d;
	}

	.fixed {
		position: fixed;
		width: 410px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.chat_to_order_text {
		font-size: 16px !important;
		
		margin-top: 11px;
	}

	.close_btn {
		position: absolute;
		right: 3%;
		top: 2%;
		width: 17px;
		z-index: 100;
	}

	.text_inside_businessdetail {
		
		font-weight: 400 !important;
		font-size: 14px !important;
	}

	.pointer {
		cursor: pointer;
	}
}
