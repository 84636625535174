.section {
	height: 100vh;
	// width: 100vw;
	top: 800px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	h1 {
		color: black;
	}
	h2 {
		color: black;
	}
}

#div01 {
	height: 100vh;
	// width: 100vw;
	top: 800px;
	left: 0px;
}

.logo {
	position: fixed;
	top: 30px;
	right: 100px;
	height: 100vh;

	img {
		width: 100%;
		height: 90vh;
	}
}

i {
	font-size: 3rem;
	color: #fff;
}
.scroll-anim {
	display: flex;
	justify-content: space-around;
	&__content {
		width: 50%;
	}
	&__images {
		width: 50%;
		position: relative;
	}
}
