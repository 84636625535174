

.dialog_content_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin: 20px 0px 40px 0px !important;
    padding: 0px !important
        /* margin: auto; */
        /* justify-items: center; */

        /* grid-template-columns: minmax(300px, 1fr);
    grid-gap: 2rem;
    justify-content: center; */
}

.dialog_content_grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin: 0px 0px 40px 0px !important;
    padding: 0px !important
}

.maincards {
    /* height: 130px !important; */
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    box-shadow: none !important;
}

.variantCard {
    box-shadow: none !important;
    width: 100%;
}

.item_frame {
    position: absolute;
    width: 98%;
    top: 0;
    right: 0;
    left: 1%;
    bottom: 0
}

.bahnschrift {
    font-family: 'Proxima-regular' !important;
}

.variant_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    min-height: 80px;
}

.variant-name {
    font-size: 15px !important;
    font-family: 'Proxima-regular' !important;
    color: #3a3a3c !important;
    line-height: normal !important;
}

.dialog_maincards {
    max-width: 100% !important;
    width: 100% !important;
    height: max-content !important;
    box-shadow: none !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 2rem !important;
    align-items: center !important;
}

.dialog_maincards1 {
    /* max-width: 100% !important;
    width: 100% !important;
    height: max-content !important;
    box-shadow: none !important;
    display: grid !important;
    grid-template-columns: 1.2fr 1fr 0.7fr !important;
    gap: 0rem !important;
    margin-top: 9px;
    align-items: center !important; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;
}

.action_card {
    padding: 0px !important;

    width: 90%;
    margin: 0px auto !important
}

.cardimgs {
    padding: 20px 75px 0px 75px !important;
    display: flex;
    justify-content: space-around;
    height: 150px !important;
}

.dividers {
    display: none !important;
}

.maincards_typo {
    text-align: left !important;
    color: #000000 !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


.dot_divider {
    width: 90px !important;
    text-decoration: dotted #C7C5C5 !important;
    text-underline-offset: 0.5em !important;
}

.maincards_typodescription {
    text-align: left !important;
    color: #828282 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    /* width: 104px !important; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.maincards_typodiv {
    text-align: left !important;
    color: #000000 !important;
    margin: "3px 0px 0px 0px" !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.disclaimer {
    font-size: 10px;
    color: "#808080" !important;
    text-align: left !important;
    line-height: normal !important;
}

.disclaimer_sub {
    font-size: 10px;
    color: "#808080" !important;
    text-align: left !important;
    line-height: normal !important;
}

.maincard_button {
    font-size: 16px !important;
    text-align: center;
    text-align: revert;
    text-align: -webkit-center;
    text-align: -ms-center;
    text-align: -moz-center;
    align-items: center;
    align-items: -webkit-center;
    align-items: -ms-center;
    align-items: -moz-center;
    display: inline-block;
}

.grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
}

.maincard_instruction {
    font-weight: 400;
    color: rgb(3, 117, 176) !important;
    background-color: rgb(255, 255, 255) !important;
    cursor: pointer;
    border-radius: 5px;
    font-size: 9px !important;
    line-height: 0px;
}

.maincard_editinstruction {
    font-weight: 400;
    color: #000000 !important;
    background-color: rgb(255, 255, 255) !important;
    cursor: pointer;
    border-radius: 5px;
    font-size: 9px !important;
    line-height: 0px;
}

.close_btn {
    display: flex !important;
    justify-content: flex-end !important;
    float: right !important;
    font-size: 16px !important;
    margin: 3px 0px 4px 0px !important;
}

.close_btn1 {
    display: flex !important;
    justify-content: flex-end !important;
    float: right !important;
    font-size: 16px !important;
    margin: 3px 7px 4px 0px !important;
}

.close_btn2 {
    display: flex !important;
    justify-content: flex-end !important;
    float: right !important;
    font-size: 16px !important;
    margin: 3px 20px 4px 0px !important;
    width: 17px;
}

.pdLayover_unit {
    font-size: 10px !important;
    color: #a39f9f;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 500 !important;
}

.pdLayover_addContainer {
    padding: 0 !important;
    margin-right: 10px;
    align-self: flex-end;
}

.dialog_instruction {
    padding: 16px !important;
}

/* .dialog_instruction::-webkit-scrollbar {
    width: 12px;
    background-color: white;
    color: black;
    overflow-y: auto !important;
  } */

.confirm_btn {
    height: 40px;
    border-radius: 10px;
}

.dialog_view_cart {
    padding: 8px 8px 4px 8px !important;
    color: #0375B0 !important;
}

.dialog_instruction_inputs {
    font-weight: 400 !important;
    font-size: 12px !important;
    background: #FFFFFF;
    /* border: 1px solid #0375B0 !important; */
    color: #000000 !important;
    width: 100% !important;
}

input[type=text]:focus {

    outline: none !important;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.dialog_instruction_buttons {
    height: 26px;
    width: 64px;
    background: #0375B0;
    border-radius: 2px;
    color: #FFFFFF !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid #FFFFFF;
}

.cart_bottom_info_bar {
    position: fixed;
    height: auto;
    bottom: 65px;
    left: 6px;
    right: 6px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: flex;
    padding: 2px !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.cart_bottom_bar {
    position: fixed;
    height: 50px;
    bottom: 0;
    left: 6px;
    right: 6px;
    background: #2bb673;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px !important;
    margin-bottom: 25px;
    z-index: 799;
}

.order_summary_band {
    position: fixed;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #D9D9D9;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px !important;
    z-index: 10;
}

.app_download_banner {
    position: fixed;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e8f8fd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 800 !important;
}

.download_btn {
    width: 150px;
    height: 35px;
    border-radius: 20px;
    border: none;
    border-style: none;
    color: white;
    font-family: 'Proxima-regular' !important;
    background-color: #00b9f5;
    line-height: normal !important;
}

.download_text {
    font-family: 'Proxima-regular' !important;
    line-height: normal !important;
}

.download_sub_text {
    font-family: 'Proxima-regular' !important;
    font-size: 11px !important;
    font-weight: 400;
    line-height: normal !important;
}

.cart_bottom_bar_shop_closed {
    position: fixed;
    height: 50px;
    bottom: 0;
    left: 6px;
    right: 6px;
    background: #808080;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px !important;
    margin-bottom: 25px;
    z-index: 10;
    color: white;
}

.shop_closed_text {
    font-size: 16px !important;
    font-family: "Proxima-regular" !important;
}

.cart_bottom_bar span {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF !important;
}

.cart_dialog_header {
    left: 9px;
    top: 30px;

    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    padding: 8px !important;
    height: 35px !important;
    border-bottom: 1px solid #0375B0;
}

.main_image_container {
    cursor: pointer;
    margin: 2px;
    padding: 6px !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    
}

.main_image_container_variant {
    padding: 0 !important;
    margin-left: 10px;
}

.card_container {
    display: flex;
    justify-content: space-between !important;
}

.btn_container {
    margin: 0px;
    padding: 0px !important;
    /* flex-grow: 1 !important; */
}

.main_add_sub_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -13px 0px 0px 0px;
    height: 22px;
    width: 118px !important;
    max-width: 118px !important;
}

.variantcard_actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px !important;
    padding: 8px !important;
    /* height: 22px; */
    width: 118px !important;
    max-width: 118px !important;
    
}

.main_add_sub_container_product {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 30px;
    padding: 0px !important;
    margin: 0px;
}

.main_add_sub_container_single {
    display: grid !important;
    grid-template-columns: 1fr !important;
    height: 37px;
    margin: -28px 0px 0px 0px;
    padding: 10px !important;
}

.main_add_sub_container_single_product {
    display: grid !important;
    grid-template-columns: 1fr !important;
    height: 30px;
    margin: 0px;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
    justify-items: center;
}

.plus_minus_text {
    font-size: 17px !important;
    color: #125d8d;
    margin: 0 !important;
    font-weight: 500 !important;
}

.plus_minus_text_grid {
    font-size: 21px !important;
    color: #125d8d;
    margin: 0 !important;
    font-weight: 500 !important;
}

.product_count {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 11px !important;
    color: #125d8d;
}

.custom_product_container {
    padding-left: 10px;
    padding-right: 10px;
}

.cutom_pd_header {
    font-size: 14px !important;
}

.write_here_text {
    color: black;
    font-size: 14px !important;
    font-weight: 300;
}

.custom_pd_box {
    border: 1px solid #4aa47d;
    width: 100%;
    background-color: #fafafa;
    border-radius: 10px;
    padding-top: 0.5rem;
}

.custom_pd {
    display: flex;
    padding-left: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
}

.green_bullet {
    background-color: #4aa47d;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
}

.custom_pd_input {
    all: unset;
    margin-left: 0.5rem;
    border: none;
    background-color: #fafafa;
    font-family: 'Proxima-regular' !important;
    font-weight: 400;
    line-height: normal !important;
    margin-top: 0.15rem;
    width: 100%;
}

.gridImage {
	width: 200px;
	height: 200px;
	padding: 15px
}

.custom_pd_input::placeholder {
    font-family: 'Proxima-regular' !important;
}

.order_instruction {
    color: #bbbbbb;
    font-family: 'Proxima-regular' !important;
    margin-bottom: 0;
    margin-top: 0.25rem;
    font-size: 0.9rem !important;
    font-weight: 400 !important;
}

.custom_pd_container {
    border: 1px solid #4aa47d;
    border-radius: 0.938rem;
    margin-top: 1.25rem;
    padding: 0.3rem;
    padding-top: 0.5rem;
    width: 100%;
    background-color: white;
}

.summary_custom_item_text {
    font-size: 0.875rem;
    margin: 0;
    margin-left: 0.5rem;
    font-family: 'Proxima-regular' !important;
    font-weight: 500;
    margin-top: 0.15rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1 !important;
    overflow: hidden;
    word-break: break-word;
}

.info_img {
    width: 0.8rem;
    height: 0.8rem;
    margin-bottom: 0.1rem;
    margin-left: 0.3rem;
}

.info_content_header {
    font-size: 1.3rem !important;
}

.info_content {
    display: flex;
    align-items: center;
}

.info_content_text {
    color: #808081;
    font-size: 1rem !important;
    margin: 0;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    margin-top: 0.3rem;
    font-family: 'Proxima-regular' !important;
}

.category-bar {
    padding-top: 20px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    position: sticky;
    top: 60px;
    background-color: white;
    z-index: 798;
}

.category-container {
    display: flex;
    gap: 30px;
}

.bestseller-text {
    margin: 0 !important;
}

.s1category-name {
    white-space: nowrap;
    padding: 5px 5px;
    border: 1px solid lightgrey;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 15px !important;
    font-weight: 400;
}

.s1category-name-selected {
    white-space: nowrap;
    padding: 5px 5px;
    border: 1px solid lightgrey;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #125d8d;
    color: white;
    font-size: 15px !important;
}

.catalog-loader-container {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unavailable_text {
    font-size: 18px !important;
    font-family: 'Proxima-regular' !important;
    color: white !important;
    text-align: center;
    margin: 0 !important;
    font-weight: 400 !important;
}

.not-available-text-container {
    width: 100px;
    height: 24px;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.not-available-text-grid {
    color: white;
    font-size: 14px !important;
}

.menu-icon-img {
    width: 80px;
    height: 80px;
}

.menu-dialog-container {
	padding-left: 20px;
	overflow-y: auto;
}

.menu-category-count {
    padding-right: 20px;
    padding-left: 20px;
}

.no-data-text-container {
    display: flex;
    text-align: center;
    font-weight: bold;
    flex-direction: column;
    border: 2px solid white;
    align-items: center;
    border-radius: 5px;
    margin-top: 30px;
}

.relative-position {
    position: relative;
}

.offer-tag-container {
    position: absolute;
    margin-top: 10px;
}

.offer_text {
    position: absolute;
    margin-top: 4px;
    margin-left: 9px;
    color: black;
    font-size: 11px !important;
    max-width: 20px;
    color: white;
    font-family: 'Proxima-regular' !important;
    text-align: center;
}

.discount-tag {
    width: 40px;
}

.best-seller-tag-container {
    position: absolute;
    right: 0;
    margin-top: 15px;
}

.best-seller-tag-img {
    height: 26px;
}

.image-not-available-text {
    text-align: center;
    margin: 14px 0 0 0;
}

.product-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    max-width: 200px;
}

.brand-name-container {
    margin-top: 10px;
}

.item_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-family: 'Proxima-regular' !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
    -webkit-line-clamp: 3 !important;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    word-break: break-word;
    text-align: center;
    color: black;
}

.item-name-optional2 {
    padding-top: 16px;
}

.bottomline_container {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-around;
}

.price-container {
    display: flex;
    flex-direction: column;
}

.products-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #e8e9eb;
    height: 60px;
}

.products-header-text {
    font-size: 20px !important;
    font-family: 'Proxima-regular' !important;
}

.flourish-icon {
    height: 13px;
}

.offer-banner {
    /* width: 90%; */
}

@media only screen and (max-width: 576px) {
    .line_clamp2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .track_status_text {
        color: #7d7d7d;
    }

    .order_status_text {
        font-size: 15px !important;
        line-height: normal !important;
        font-weight: 200 !important;
    }

    .order_status_title {
        font-size: 16px !important;
        line-height: normal !important;
        margin-top: 0;
    }
    
    .otp_wrong_text {
        font-family: 'Proxima-regular';
        margin: 0;
        color: red;
        font-weight: 400;
        text-align: center;
    }

    .orderdetails_header {
        color: #0375B0;
        position: fixed;
        top: 23%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .order_details_text {
        font-size: 19px !important;
    }

    .summary_total_container {
        background-color: white;
        border: 2px solid #3cb37b;
        margin-top: 10px;
        text-align: center;
        padding: 5px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin-right: 10px;
        margin-left: 10px;
    }

    .item_saving_text {
        color: #c3c3c3;
        font-family: 'Proxima-regular' !important;
        margin: 0;
    }

    .summary_total_text {
        font-family: 'Proxima-regular' !important;
        font-size: 16px !important;
        margin: 0;
    }

    .summary_pd_text {
        font-family: 'Proxima-regular' !important;
        font-weight: 400;
        line-height: normal !important;
    }

    .summary_pd_num {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500;
    }

    .summary_cutout_price {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500;
        color: #7d7d7d;
        font-size: 12px !important;
        text-decoration: line-through;
        margin-right: 10px;
    }

    .summary_icon_texts {
        color: #7d7d7d;
        font-family: 'Proxima-regular' !important;
    }

    .order_id_date_text {
        color: #7d7d7d;
        font-family: 'Proxima-regular' !important;
        font-size: 14px !important;
    }

    .order_summary_containers {
        background-color: white;
        border-radius: 10px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .summary_horiz_divider {
        border: 1px solid #e1e1e1;
        width: 20%;
        margin-bottom: 30px;
    }

    .loginpage_link {
        color:rgb(3, 117, 176);
        font-family: 'Proxima-regular' !important;
    }

    .senton_mobile_text {
        margin-left: 4%;
        color: #808081;
        font-family: 'Proxima-regular' !important;
    }

    .number_input {
        font-size: 17px !important;
        height: 50px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        border-radius: 10px;
        width: 92%;
        border: 1px solid #115e8d !important;
        box-shadow: none;
        letter-spacing: 1px;
        padding-left: 10px;
        text-align: left;
    }

    .number_input:focus {
        all: unset;
        font-size: 17px !important;
        height: 48px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        border-radius: 10px;
        width: 89%;
        border: 2px solid #115e8d !important;
        box-shadow: none;
        letter-spacing: 1px;
        padding-left: 10px;
        text-align: left;
    }

    .mobile_number_text {
        font-family: 'Proxima-regular' !important;
        font-size: 17px !important;
        margin-bottom: 15px;
    }

    .agreement_text {
        font-family: 'Proxima-regular' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    .agreement_continue_btn_disabled {
        width: 92%;
        height: 40px;
        border-radius: 10px;
        border-style: none;
        border: none;
        background-color: lightgrey;
        color: black;
        font-family: 'Proxima-regular' !important;
        font-size: 20px !important;
        margin-top: 15px;
    }

    .agreement_continue_btn {
        width: 92%;
        height: 40px;
        border-radius: 10px;
        border-style: none;
        border: none;
        background-color: #115e8d;
        color: white;
        font-family: 'Proxima-regular' !important;
        font-size: 20px !important;
        margin-top: 15px;
    }

    .agreement_check_box:checked {
        color: green;
    }

    .horizontal_divider {
        width: 130px;
        border: 0.5px solid grey;
        opacity: 0.2;
    }

    .address_head {
        font-size: 17px !important;
        font-weight: 700 !important;
        margin-bottom: 0;
    }

    .wholeaddress_text {
        color: #808081;
        margin-top: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
        word-break: break-word;
    }

    .addresslines_text {
        margin-top: 0;
        color: #808081;
        font-size: 14px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .select_address_title {
        color: black;
        font-size: 22px !important;
        margin-top: 0;
    }

    .address_change_text {
        margin-bottom: 0;
        color: #e48e39;
    }

    .deliver_to_home_text {
        margin-bottom: 0;
        font-size: 16px !important;

    }

    .cart_addaddress_text {
        font-size: 20px !important;
        margin-bottom: 0;
    }

    .cart_addaddress_subtext {
        margin-top: 0;
        color: #808081;
        font-weight: 400;
    }

    .bahnschrift {
        font-family: 'Proxima-regular' !important;
    }

    .cart_address {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bill_container {
        border: 1px solid #4aa47d;
        border-radius: 15px;
        margin-top: 20px;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .bill_subtext {
        color: #808081;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
    }
    
    .bill_amount {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 300;
    }
    
    .bill_amount_bold {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 600;
    }

    .cashback_subtext {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 500;
        font-size: 12px !important;
        color: #808081;
    }

    .bill_large_font_size {
        font-size: 16px !important;
        font-family: 'Proxima-regular' !important;
    }

    .cart_divider {
        border-bottom: 1px solid #e4e4e4;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .search_empty_text {
        font-size: 20px !important;
        font-weight: 700 !important;
        font-family: 'Proxima-regular' !important;
        color: black;
        text-align: center;
    }

    .chat_enquire_text {
        font-size: 15px !important;
        font-family: 'Proxima-regular' !important;
        color: #0375B0 !important;
        text-align: center;
    }

    .main_image_container {
        cursor: pointer;
        margin: 2px;
        padding: 6px !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        
    }
    
    .main_image_container_variant {
        padding: 0 !important;
        margin-left: 10px;
    }
    
    .card_container {
        display: flex;
        justify-content: space-between !important;
    }
    
    .btn_container {
        margin: 0px;
        padding: 0px !important;
        /* flex-grow: 1 !important; */
    }
    
    .main_add_sub_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -13px 0px 0px 0px;
        height: 22px;
        width: 118px !important;
        max-width: 118px !important;
    }
    
    .variantcard_actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px !important;
        padding: 8px !important;
        /* height: 22px; */
        width: 118px !important;
        max-width: 118px !important;
        
    }
    
    .main_add_sub_container_product {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        height: 30px;
        padding: 0px !important;
        margin: 0px;
    }
    
    .main_add_sub_container_single {
        display: grid !important;
        grid-template-columns: 1fr !important;
        height: 37px;
        margin: -28px 0px 0px 0px;
        padding: 10px !important;
    }

    .product_availability_container {
        display: grid !important;
        grid-template-columns: 1fr !important;
        height: 37px;
        margin: -35px 0px 0px 0px;
        padding: 10px !important;
    }

    .product_availability_text {
        font-family: 'Proxima-regular' !important;
        color: black;
        font-weight: 400;
        overflow-wrap: break-word;
        margin: 0;
    }
    
    .main_add_sub_container_single_product {
        display: grid !important;
        grid-template-columns: 1fr !important;
        height: 30px;
        margin: 0px;
        padding: 0px !important;
        width: 100% !important;
        max-width: 100% !important;
        justify-items: center;
    }

    .cart_bottom_info_bar {
        position: fixed;
        height: auto;
        bottom: 65px;
        left: 6px;
        right: 6px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 5px;
        display: flex;
        padding: 2px !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }

    .confirm_btn {
        height: 40px;
        border-radius: 10px;
    }
    
    .dialog_view_cart {
        padding: 8px 8px 4px 8px !important;
        color: #0375B0 !important;
    }

    .close_btn2 {
        display: flex !important;
        justify-content: flex-end !important;
        float: right !important;
        font-size: 16px !important;
        margin: 3px 20px 4px 0px !important;
        width: 17px;
    }
    
    .pdLayover_unit {
        font-size: 10px !important;
        color: #a39f9f;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
    }
    
    .pdLayover_addContainer {
        padding: 0 !important;
        margin-right: 10px;
        align-self: flex-end;
    }

    .dialog_maincards {
        max-width: 100% !important;
        width: 100% !important;
        height: max-content !important;
        box-shadow: none !important;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        gap: 2rem !important;
        align-items: center !important;
    }
    
    .dialog_maincards1 {
        /* max-width: 100% !important;
        width: 100% !important;
        height: max-content !important;
        box-shadow: none !important;
        display: grid !important;
        grid-template-columns: 1.2fr 1fr 0.7fr !important;
        gap: 0rem !important;
        margin-top: 9px;
        align-items: center !important; */
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 20px;
    }
    
    .action_card {
        padding: 0px !important;
    
        width: 90%;
        margin: 0px auto !important
    }

    .variantCard {
        box-shadow: none !important;
        width: 100%;
    }
    
    .item_frame {
        position: absolute;
        width: 98%;
        top: 0;
        right: 0;
        left: 1%;
        bottom: 0
    }
    
    .variant_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    
    .variant-name {
        font-size: 15px !important;
        font-family: 'Proxima-regular' !important;
        color: #3a3a3c !important;
        line-height: normal !important;
        word-break: break-word ;
    }

    /* .grid {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 0.5 rem !important;
    } */

    .add_text {
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .add_text_grid {
        font-size: 13px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .options_text_grid {
        font-size: 13px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .options_text {
        font-size: 16px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        margin-top: 2px !important;
        font-weight: 400 !important;
    }

    .price_without_discount {
        display: inline-block
    }

    .grid {
        margin: 20px;
      /* height:800px; */
      background-color:#fff;
      display:grid;
      grid-template-columns: 1fr 1fr;
      grid-row: auto auto;
      grid-column-gap: 3px;
      grid-row-gap: 3px;
      margin-left: 3px;
      margin-right: 3px;
      /* justify-items: stretch;
  align-items: stretch; */
        /* margin: auto; */
        /* justify-items: center; */
    
        /* grid-template-columns: minmax(300px, 1fr);
        grid-gap: 2rem;
        justify-content: center; */
    }

    .grid2 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 0.5rem !important;
    }
    
    .box{
        /* height: 409px; */
        /* padding:20px; */
        /* border-radius:10px; */
        border-radius: 0 !important;
        box-shadow: none !important;
        border: 1px solid #aebbd1;
        color:#fff;
        display:flex;
        flex-direction: column;
        font-size:40px;
        font-family:sans-serif;
        max-width: 200px;
      }

    .brand_name {
        text-align: center;
        margin-top: 0 !important;
        color: grey;
        font-size: 10px !important;
        font-weight: normal !important;
        margin-bottom: 5px !important;
        line-height: normal !important;
    }

    .dialog_content_grid {
        display: block !important;
        grid-template-columns: 1fr !important;
        gap: 1rem !important;
        margin: 20px 0px 40px 0px !important;
        padding: 0px !important;
    }

    .backgroundContainer {
        position: absolute;
    }

    .pdDetailOfferTag {
        position: absolute;
        z-index: 1;
    }

    .normal_item {
        color: #939598 !important;
        font-size: smaller !important;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
    }

    .normal_item2 {
        color: #939598 !important;
        font-size: 12px !important;
        font-weight: 500;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        /* margin-right: 10px !important; */
    }

    .product_name {
        font-size: 16px !important;
        color: #3a3a3c !important;
        font-weight: 500 !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 5px !important;
        margin-right: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
    }

    .cart_pd_price {
        font-size: 16px !important;
        padding-left: 0 !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 400 !important;
        padding-right: 5px;
    }

    .cart_price_container {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-top: 6px;
        /* width: 100px; */
    }

    .s1cat_name {
        font-family: 'Proxima-regular' !important;
        font-size: 18px !important;
        color: #434345;
        word-break: break-word;
    }

    .allproducts_header {
        font-family: 'Proxima-regular' !important;
        font-size: 22px !important;
        color: #125d8d;
    }

    .bestseller_container {
        overflow-x: scroll;
        display: flex;
        flex-wrap: nowrap;
    }

    .vertical_divider {
        border-right: 1px solid grey !important;
        height: 95%;
        align-self: flex-end;
        opacity: 0.2;
    }

    .bestseller_name {
        font-size: 14px !important;
        padding-right: 10px;
        padding-left: 10px;
        color: black;
        font-family: 'Proxima-regular' !important;
        text-align: center;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .bestseller_without_discount {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin-top: -5px !important;
    }

    .bestseller_unit_nodiscount {
        margin-top: 5px !important;
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .bestseller_normal_item {
        color: #939598 !important;
        font-size: smaller !important;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        align-self: flex-end;
        padding-bottom: 1px;
    }

    .bestseller_unit {
        margin-top: 7px !important;
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .s1cat_count {
        color: #434345 !important;
        font-family: 'Proxima-regular' !important;
        font-size: 16px !important;
        padding-left: 5px;
        padding-right: 15px;
    }

    .offer_text2 {
        position: absolute;
        margin: 0;
        left: 0;
        right: 5%;
        top: 15%; 
        text-align: center;
        font-family: 'Proxima-regular' !important;
        font-size: 13px !important;
    }

    .plus_minus_text {
        font-size: 17px !important;
        color: #125d8d;
        margin: 0 !important;
        font-weight: 500 !important;
    }

    .plus_minus_text_grid {
        font-size: 21px !important;
        color: #125d8d;
        margin: 0 !important;
        font-weight: 500 !important;
    }

    .product_count {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-size: 13px !important;
        color: #125d8d;
    }

    .variant_count {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-size: 14px !important;
    }

    .product_count2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-size: 14px !important;
        color: #125d8d;
    }

    .pd_info_offer_text {
        position: absolute;
        color: black;
        margin-left: 15px;
        font-size: 15px !important;
    }


    .dialog_content_grid2 {
        display: block !important;
        grid-template-columns: 1fr !important;
        gap: 1rem !important;
        margin: 0px 0px 0px 0px !important;
        padding-bottom: 30px !important;
    }

    .searchName {
        font-size: 16px !important;
        border: 1px solid rgb(3, 117, 176);
        ;
        border-radius: 18px;
    }

    .cart_container {
        display: flex;
        flex-direction: column;
        box-shadow: none !important;
    }


    .disclaimer {
        font-size: 8px !important;
        color: "#808080";
        text-align: left !important;
    }

    .maincards {
        /* height: 130px !important; */
        display: flex;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        box-shadow: none !important;
    }

    .preferred_heading {
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        font-weight: 700 !important;
    }

    .menu_category {
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        word-break: break-all;
    }

    .cardimgs {
        padding: 0px !important;
        display: flex;
        justify-content: space-around;
        height: 135px !important;
        max-width: 158px !important;
        border-radius: 15px;
        border: 1px solid #eadede;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 135px;
        object-fit: cover;
    }

    .dividers {
        display: block !important;
        background-color: #186190;
        opacity: 0.2;
    }

    .menu_icon {
        position: fixed;
        top: auto;
        bottom: 35px;
        right: -3%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    .menu_icon2 {
        position: fixed;
        top: auto;
        bottom: 50px;
        right: -3%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    .menu_btn_container {
        background-color: #3a3a3c;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-right: 20px;
        padding-left: 20px; */
        border-radius:5px;
        color: white;
        width: 150px;
    }

    .menu_btn_text {
        font-size: 19px !important;
        font-family: 'Proxima-regular' !important;
        line-height: normal !important;
        padding-left: 10px;
        margin-top: 13px;
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .old_price {
        padding-top: 3px;
    }

    .maincards_typo {
        text-align: left !important;
        color: #000000 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
    }

    /* .product_name {
        text-align: left !important;
        color: #000000 !important;
        font-weight: 550 !important;
        font-size: 20px !important;
        padding-bottom: 8px;
    } */

    .cart_normal_pd_name {
        font-size: 15px !important;
        margin-left: 5px !important;
        color: black !important;
        margin: 0;
        font-family: 'Proxima-regular' !important;
        font-weight: 400 !important;
        line-height: normal !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .view_cart_text {
        font-size: 19px !important;
    }

    .order_summary_band_text {
        font-size: 16px !important;
        color: black;
        font-family: 'Proxima-regular' !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .view_cart_item {
        font-size: 17px !important;
    }

    .product_normal_price {
        font-size: larger !important;
    }

    .product_normal_price2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .cart_old_price {
        display: 'inline';
        text-decoration: line-through;
        font-size: smaller !important;
    }

    .send_order_old_price {
        font-weight: lighter !important;
        font-size: 14px !important;
        padding-left: 5px;
    }

    .cart_old_price_nodisplay {
        font-size: 13px !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 400 !important;
        padding-right: 5px;
    }

    .product_offer_price {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: 'black' !important;
    }

    .product_offer_price_nodiscount {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin-bottom: -10px !important;
    }

    .product_offer_price_nodiscount1 {
        padding-top: 10px !important;
    }

    .product_offer_price2 {
        font-size: 14px !important;
        color: #3a3a3c;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        padding-left: 6px;
    }

    .pd_brandName {
        font-size: 14px !important;
        line-height: normal !important;
        color: #939598 !important;
        font-family: 'Proxima-regular' !important;
    }

    .maincards__summary_main {
        text-align: left !important;
        color: #000000 !important;
        font-weight: 600 !important;
        font-size: 12px !important;
    }

    .maincards__summary {
        text-align: left !important;
        color: #828282 !important;
        font-weight: 400 !important;
        font-size: 12px !important;
    }

    .maincards__summary_button {
        text-align: left !important;
        color: #000000 !important;
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .maincards_typoprice {
        text-align: left !important;
        color: #000000 !important;
        font-weight: 700 !important;
        font-size: 14px !important;
    }

    .maincards_typodescription {
        text-align: left !important;
        color: #828282 !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        /* width: 170px !important; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .maincards_typodescription2 {
        text-align: left !important;
        font-family: 'Proxima-regular' !important;
        color: #939598 !important;
        font-size: 13px !important;
        line-height: normal !important;
        font-weight: 300 !important;
        margin-right: 6px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
        word-break: break-word;
    }

    .maincard_button {
        font-size: 16px !important;
        text-align: center;
        text-align: revert;
        text-align: -webkit-center;
        text-align: -ms-center;
        text-align: -moz-center;
        align-items: center;
        align-items: -webkit-center;
        align-items: -ms-center;
        align-items: -moz-center;
        display: inline-block;
    }

    .product_availability_btn {
        font-size: 16px !important;
        text-align: center;
        text-align: revert;
        text-align: -webkit-center;
        text-align: -ms-center;
        text-align: -moz-center;
        align-items: center;
        align-items: -webkit-center;
        align-items: -ms-center;
        align-items: -moz-center;
        display: inline-block;
    }

    .cart_add_btn {
        width: 25px !important;
    }

    .special_instruction {
        margin-top: 20px !important;
        display: flex;
        align-items: flex-start;
        padding-bottom: 15px !important;
    }

    .cart_instructionView_modalOpen {
        text-align: initial;
        left: 0px;
        margin-top: 3px;
        position: relative;
        padding: 0px 10px;
        width: 125px;
        right: 0px;
        margin-bottom: 7px;
    }

    .product_modalOpen {
        position: absolute;
        width: 30.4%;
        right: 0px;
        text-align: left;
        line-height: 12px;
        margin: 10px -2px 0px 0px !important;
        left: 0px;
        margin-top: 8px;
        position: relative;
        padding: 0px;
        width: 100%;
        right: 0px;
    }

    .instructionText {
        font-size: 9px !important;
        color: #828282 !important;
    }

    .maincard_instruction {
        font-weight: 400;
        color: rgb(3, 117, 176) !important;
        background-color: rgb(255, 255, 255) !important;
        cursor: pointer;
        border-radius: 5px;

        font-size: 9px !important;
    }

    .maincard_instruction1 {
        font-weight: 400;
        color: rgb(3, 117, 176) !important;
        cursor: pointer;
        font-size: 9px !important;
        border-radius: 5px;
        line-height: 0px;
    }

    .edit_instruction {
        display: flex;
    }

    .tags_container {
        display: flex;
        margin-bottom: 15px;
    }

    .close_btn {
        display: flex !important;
        justify-content: flex-end !important;
        float: right !important;
        font-size: 16px !important;
        margin: 0px 0px 4px 0px !important;
    }

    .close_btn1 {
        display: flex !important;
        justify-content: flex-end !important;
        float: right !important;
        font-size: 16px !important;
        margin: 3px 7px 4px 0px !important;
    }

    .dialog_instruction {
        padding: 16px !important;
    }

    .dialog_instruction_options {
        margin-top: 30px;
        margin-left: 25px;
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        line-height: normal !important;
    }

    .dialog_instruction_inputs {
        font-weight: 400 !important;
        font-size: 12px !important;
        background: #FFFFFF;
        border: 1px solid #0375B0;
        color: #000000 !important;
    }

    .dialog_instruction_buttons {
        height: 26px;
        width: 64px;
        background: #0375B0;
        border-radius: 2px;
        color: #FFFFFF !important;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        border: 1px solid #FFFFFF;
    }

    .dot.selected {
        background: none !important;
    }

    .maincards_typodiv {
        text-align: left !important;
        color: #000000 !important;
        margin: "3px 0px 0px 0px" !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        margin: 5px 0px 5px 0px !important;
    }

    .cart_bottom_bar span {
        font-weight: 800;
        color: #FFFFFF !important;
    }

    .send_order_text {
        font-size: 19px !important;
    }

    .cart_dialog_header_title {
        font-weight: 600 !important;
        font-size: 16px !important;
        color: #000000 !important;
    }

    .cart_dialog_header_item {
        font-weight: 400 !important;
        font-size: 16px !important;
        color: #828282;
        margin-left: 10px;
    }

    .savings_text {
        font-weight: 600 !important;
        font-size: 16px !important;
        color: #2bb673;
        margin-left: 10px;
    }

    .carousel .thumbs-wrapper {
        display: none;
    }

    .main_content_product {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    .productName_content {
        margin-top: 23px;
        width: 65%;
    }

    .productName {
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 700;
        font-size: 16px !important;
        color: #000000 !important;
        word-break: break-word;
    }

    .price {
        display: inline-table;
        float: left;
        /* line-height: 37px; */
        /* color: #000000; */
        font-size: 16px !important;
    }

    .productCount {
        width: 33%;
        margin-top: 23px;
        min-height: 36px;
        text-align: center;
        position: relative;
    }

    .productDesc {
        width: 88%;
        margin: 0px auto;
    }

    .productDescheading {
        font-size: 12px !important;
        color: #000000;
        font-weight: 400;
    }

    .productDesctext {
        font-size: 14px !important;
        color: #828282;
        font-weight: 400;
        word-break: break-word;
        margin: 0px;
        padding: 0px;
    }

    .productinfo {
        font-size: 12px !important;
        color: #000000;
        font-weight: 400;
    }

    .productinfotext {
        font-size: 9px !important;
        color: #828282;
        font-weight: 400;
    }

    .productUnit {
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .productUnit_incart {
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 14px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .productUnit1 {
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin: 0px;
        font-size: 20px !important;
    }

    .bar {

        display: inline;
    }

    .specialEditIcon {
        width: 14%;
        float: left;
        display: inline-block;
    }

    .specialAddIcon {
        width: 12%;
        float: left;
        display: inline-block;
    }

    .specialAddText {
        width: 84%;
        height: auto;
        /* line-height: 1.2; */
        white-space: nowrap;
        float: right;
        display: inline-block;
        font-size: 9px !important;
        color: #0375b0 !important;
        margin-top: 8.5%;
    }

    .specialInstructionText {
        font-size: 13px !important;
        margin-left: 14px;
        font-family: 'Proxima-regular' !important;
    }

    .cart_specialInstructionText {
        font-size: 13px !important;
        margin-left: 14px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
    }

    .cart_plustext {
        font-size: 18px !important;
        font-weight: 300 !important;
    }

    .plus_text {
        font-size: 18px !important;
    }

    .specialEditText {
        width: 75%;
        height: auto;
        line-height: 1.2;
        white-space: break-spaces;
        display: inline-block;
        font-size: 9px !important;
        color: #828282 !important;
        word-break: break-word;
        margin: 4px 0px 0px 4px;
    }

}

@media only screen and (max-width: 299px) and (min-width: 0px) {
    .carousel .control-dots {
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 26% !important;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px auto;
        display: block;
        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (max-width: 365px) and (min-width: 289px) {
    .carousel .control-dots {
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 23% !important;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px auto;
        display: block;
        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (max-width: 500px) and (min-width: 366px) {
    .carousel .control-dots {
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 18% !important;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px auto;
        display: block;
        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (max-width: 765px) and (min-width: 501px) {
    .carousel .control-dots {
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 20% !important;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px auto;
        display: block;
        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (min-width: 576px) {
    .menu_icon {
        position: fixed;
        top: auto;
        bottom: 10px;
        left: 50%;
        transform: translateX(140%);
        z-index: 10;
    }

    .menu_icon2 {
        position: fixed;
        top: auto;
        bottom: 80px;
        left: 50%;
        transform: translateX(140%);
        z-index: 10;
    }

    .bestseller_container {
        overflow-x: scroll !important;
        display: flex;
        flex-wrap: nowrap;
        user-select: none;
    }

    .bestseller_container::-webkit-scrollbar-thumb {
        background-color: #888; /* Adjust the color as needed */
      }

      .bestseller_container::-webkit-scrollbar-thumb:hover {
        background-color: black; /* Color on hover */
      }
      
    .cardimgs {
        padding: 0px !important;
        display: flex;
        justify-content: space-around;
        height: 135px !important;
        max-width: 158px !important;
        border-radius: 15px;
        border: 1px solid #eadede;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 135px;
        object-fit: cover;
    }

    .add_text {
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .options_text {
        font-size: 16px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        margin-top: 2px !important;
        font-weight: 400 !important;
    }

    .bestseller_name {
        font-size: 14px !important;
        padding-right: 10px;
        padding-left: 10px;
        color: black;
        font-family: 'Proxima-regular' !important;
        text-align: center;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .bestseller_without_discount {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin-top: -5px !important;
    }

    .bestseller_unit_nodiscount {
        margin-top: 5px !important;
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .bestseller_normal_item {
        color: #939598 !important;
        font-size: smaller !important;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        align-self: flex-end;
        padding-bottom: 1px;
    }

    .bestseller_unit {
        margin-top: 7px !important;
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .allproducts_header {
        font-family: 'Proxima-regular' !important;
        font-size: 22px !important;
        color: #125d8d;
    }

    .s1cat_name {
        font-family: 'Proxima-regular' !important;
        font-size: 18px !important;
        color: #434345;
        word-break: break-word;
    }

    .s1cat_count {
        color: #434345 !important;
        font-family: 'Proxima-regular' !important;
        font-size: 16px !important;
        padding-left: 5px;
        padding-right: 15px;
    }

    .box{
        /* height: 409px; */
        /* padding:20px; */
        /* border-radius:10px; */
        border-radius: 0 !important;
        box-shadow: none !important;
        border: 1px solid #aebbd1;
        color:#fff;
        display:flex;
        flex-direction: column;
        max-width: 200px;
      }

      .brand_name {
        text-align: center;
        margin-top: 0 !important;
        color: grey;
        font-size: 10px !important;
        font-weight: normal !important;
        margin-bottom: 5px !important;
        line-height: normal !important;
    }

    .product_offer_price {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: 'black' !important;
    }

    .product_offer_price_nodiscount {
        font-size: larger !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin-bottom: -10px !important;
    }

    .product_offer_price_nodiscount1 {
        padding-top: 10px !important;
    }

    .normal_item {
        color: #939598 !important;
        font-size: smaller !important;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
    }

    .productUnit {
        display: inline-block;
        color: #a39f9f;
        font-weight: normal;
        margin-left: 5px;
        font-size: 9px !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 2px !important;
    }

    .add_text_grid {
        font-size: 13px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .options_text_grid {
        font-size: 13px !important;
        font-family: 'Proxima-regular' !important;
        color: #125d8d !important;
        text-align: center;
        margin: 0 !important;
        font-weight: 400 !important;
    }

    .plus_minus_text_grid {
        font-size: 21px !important;
        color: #125d8d;
        margin: 0 !important;
        font-weight: 500 !important;
        cursor: pointer;
    }

    .product_count2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-size: 14px !important;
        color: #125d8d;
        font-weight: 600 !important;
    }

    .product_count {
        font-weight: 600 !important;
    }

    .dividers {
        display: block !important;
        background-color: #186190;
        opacity: 0.2;
    }

    .grid {
        margin: 20px;
      background-color:#fff;
      display:grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));;
      grid-row: auto auto;
      grid-column-gap: 3px;
      grid-row-gap: 3px;
      margin-left: 3px;
      margin-right: 3px;
    }

    .tags_container {
        display: flex;
        margin-bottom: 15px;
    }

    .offer_text2 {
        position: absolute;
        margin: 0;
        left: 0;
        right: 5%;
        top: 15%; 
        text-align: center;
        font-family: 'Proxima-regular' !important;
        font-size: 13px !important;
    }

    .grid2 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 0.5rem !important;
    }

    .product_name {
        font-size: 16px !important;
        color: #3a3a3c !important;
        font-weight: 500 !important;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 5px !important;
        margin-right: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
    }

    .normal_item2 {
        color: #939598 !important;
        font-size: 12px !important;
        font-weight: 500;
        text-decoration: line-through;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        /* margin-right: 10px !important; */
    }

    .product_normal_price2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .product_offer_price2 {
        font-size: 14px !important;
        color: #3a3a3c;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        padding-left: 6px;
    }

    .maincards_typodescription2 {
        text-align: left !important;
        font-family: 'Proxima-regular' !important;
        color: #939598 !important;
        font-size: 13px !important;
        line-height: normal !important;
        font-weight: 300 !important;
        margin-right: 6px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
        word-break: break-word;
    }

    .product_availability_container {
        display: grid !important;
        grid-template-columns: 1fr !important;
        height: 37px;
        margin: -35px 0px 0px 0px;
        padding: 10px !important;
    }

    .product_availability_text {
        font-family: 'Proxima-regular' !important;
        color: black;
        font-weight: 400;
        overflow-wrap: break-word;
        margin: 0;
        font-size: 14px !important;
    }

    .product_availability_btn {
        font-size: 16px !important;
        text-align: center;
        text-align: revert;
        text-align: -webkit-center;
        text-align: -ms-center;
        text-align: -moz-center;
        align-items: center;
        align-items: -webkit-center;
        align-items: -ms-center;
        align-items: -moz-center;
        display: inline-block;
    }

    .special_instruction {
        margin-top: 20px !important;
        display: flex;
        align-items: flex-start;
        padding-bottom: 15px !important;
    }

    .maincard_instruction1 {
        font-weight: 400;
        color: rgb(3, 117, 176) !important;
        cursor: pointer;
        font-size: 9px !important;
        border-radius: 5px;
        line-height: 0px;
    }

    .edit_instruction {
        display: flex;
    }

    .specialInstructionText {
        font-size: 13px !important;
        margin-left: 14px;
        font-family: 'Proxima-regular' !important;
    }

    .plus_text {
        font-size: 18px !important;
    }

    .cart_bottom_bar {
        left: 50%;
        transform: translateX(-50%);
        width: 390px;
        cursor: pointer;
    }

    .view_cart_item {
        font-size: 17px !important;
        font-weight: 600 !important;
    }

    .view_cart_text {
        font-size: 19px !important;
        font-weight: 700 !important;
    }

    .dialog_instruction_options {
        margin-top: 30px;
        margin-left: 25px;
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        line-height: normal !important;
    }

    .search_empty_text {
        font-size: 20px !important;
        font-weight: 700 !important;
        font-family: 'Proxima-regular' !important;
        color: black;
        text-align: center;
    }

    .chat_enquire_text {
        font-size: 15px !important;
        font-family: 'Proxima-regular' !important;
        color: #0375B0 !important;
        text-align: center;
    }

    .dialog_instruction {
        padding: 16px !important;
    }
    
    preferred_heading {
        font-size: 18px !important;
        font-family: 'Proxima-regular' !important;
        font-weight: 700 !important;
    }

    .menu_category {
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        word-break: break-all;
        font-size: 14px !important;
    }

    .cart_dialog_header_title {
        font-weight: 600 !important;
        font-size: 16px !important;
        color: #000000 !important;
    }

    .cart_dialog_header_item {
        font-weight: 400 !important;
        font-size: 16px !important;
        color: #828282;
        margin-left: 10px;
    }

    .savings_text {
        font-weight: 600 !important;
        font-size: 16px !important;
        color: #2bb673;
        margin-left: 10px;
    }

    .dialog_content_grid2 {
        display: block !important;
        grid-template-columns: 1fr !important;
        gap: 1rem !important;
        margin: 0px 0px 0px 0px !important;
        padding-bottom: 30px !important;
    }

    .cart_container {
        display: flex;
        flex-direction: column;
        box-shadow: none !important;
    }

    .cart_normal_pd_name {
        font-size: 15px !important;
        margin-left: 5px !important;
        color: black !important;
        margin: 0;
        font-family: 'Proxima-regular' !important;
        font-weight: 400 !important;
        line-height: normal !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .cart_pd_price {
        font-size: 16px !important;
        padding-left: 0 !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 400 !important;
        padding-right: 5px;
    }

    .cart_old_price_nodisplay {
        font-size: 13px !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 400 !important;
        padding-right: 5px;
    }

    .cart_specialInstructionText {
        font-size: 13px !important;
        margin-left: 14px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
    }

    .cart_plustext {
        font-size: 18px !important;
        font-weight: 300 !important;
        font-family: 'Proxima-regular' !important;
    }

    .specialEditText {
        width: 75%;
        height: auto;
        line-height: 1.2;
        white-space: break-spaces;
        display: inline-block;
        font-size: 9px !important;
        color: #828282 !important;
        word-break: break-word;
        margin: 4px 0px 0px 4px;
    }

    .bill_container {
        border: 1px solid #4aa47d;
        border-radius: 15px;
        margin-top: 20px;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .bill_subtext {
        font-size: 14px !important;
        color: #808081;
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
    }
    
    .bill_amount {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 300;
        font-size: 14px !important;
    }
    
    .bill_amount_bold {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 600;
        font-size: 14px !important;
    }

    .cashback_subtext {
        font-family: 'Proxima-regular' !important;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 500;
        font-size: 12px !important;
        color: #808081;
    }

    .bill_large_font_size {
        font-size: 16px !important;
        font-family: 'Proxima-regular' !important;
    }

    .cart_divider {
        border-bottom: 1px solid #e4e4e4;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .address_change_text {
        margin-bottom: 0;
        color: #e48e39;
        font-size: 14px !important;
    }

    .deliver_to_home_text {
        margin-bottom: 0;
        font-size: 16px !important;

    }

    .addresslines_text {
        margin-top: 0;
        color: #808081;
        font-size: 14px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    .cart_address {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .select_address_title {
        color: black;
        font-size: 22px !important;
        margin-top: 0;
    }

    .address_head {
        font-size: 17px !important;
        font-weight: 700 !important;
        margin-bottom: 0;
    }

    .wholeaddress_text {
        color: #808081;
        margin-top: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden;
        word-break: break-word;
        font-size: 14px !important;
    }

    .cart_bottom_bar_shop_closed {
        position: fixed;
        height: 50px;
        bottom: 0;
        left: 6px;
        right: 6px;
        background: #808080;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px !important;
        margin-bottom: 25px;
        z-index: 10;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        width: 390px;
    }
    
    .shop_closed_text {
        font-size: 16px !important;
        font-family: "Proxima-regular" !important;
    }

    .app_download_banner {
        display: none;
    }

    .mobile_number_text {
        font-family: 'Proxima-regular' !important;
        font-size: 17px !important;
        margin-bottom: 15px;
    }

    .agreement_text {
        font-family: 'Proxima-regular' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    .agreement_continue_btn_disabled {
        width: 92%;
        height: 40px;
        border-radius: 10px;
        border-style: none;
        border: none;
        background-color: lightgrey;
        color: black;
        font-family: 'Proxima-regular' !important;
        font-size: 20px !important;
        margin-top: 15px;
    }

    .agreement_continue_btn {
        width: 92%;
        height: 40px;
        border-radius: 10px;
        border-style: none;
        border: none;
        background-color: #115e8d;
        color: white;
        font-family: 'Proxima-regular' !important;
        font-size: 20px !important;
        margin-top: 15px;
    }

    .number_input {
        font-size: 17px !important;
        height: 50px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        border-radius: 10px;
        width: 92%;
        border: 1px solid #115e8d !important;
        box-shadow: none;
        letter-spacing: 1px;
        padding-left: 10px;
        text-align: left;
    }

    .number_input::-webkit-inner-spin-button,
    .number_input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional: Adjust the margin as needed */
    }

    .loginpage_link {
        color:rgb(3, 117, 176);
        font-family: 'Proxima-regular' !important;
    }

    .login_close {
        transform: translate(550%) !important;
        left: 50% !important;
    }

    .number_input:focus {
        all: unset;
        font-size: 17px !important;
        height: 48px;
        font-family: 'Proxima-regular' !important;
        font-weight: 300 !important;
        border-radius: 10px;
        width: 89%;
        border: 2px solid #115e8d !important;
        box-shadow: none;
        letter-spacing: 1px;
        padding-left: 10px;
        text-align: left;
    }

    .mobile_number_text {
        font-family: 'Proxima-regular' !important;
        font-size: 17px !important;
        margin-bottom: 15px;
    }

    .senton_mobile_text {
        margin-left: 4%;
        color: #808081;
        font-family: 'Proxima-regular' !important;
        font-size: 14px !important;
    }

    .otp_wrong_text {
        font-family: 'Proxima-regular';
        margin: 0;
        color: red;
        font-weight: 400;
        text-align: center;
        font-size: 14px !important;
    }

    .cart_addaddress_text {
        font-size: 20px !important;
        margin-bottom: 0;
    }

    .cart_addaddress_subtext {
        margin-top: 0;
        color: #808081;
        font-weight: 400;
        font-size: 14px !important;
    }

    .order_summary_band {
        width: 410px;
        transform: translate(-50%);
        left: 50%;
    }

    .order_summary_band_text {
        font-size: 16px !important;
        color: black;
        font-family: 'Proxima-regular' !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .track_status_text {
        color: #7d7d7d;
        font-size: 14px !important;
    }

    .orderdetails_header {
        color: #0375B0;
        position: fixed;
        top: 23%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 410px;
    }

    .order_details_text {
        font-size: 19px !important;
    }

    .summary_icon_texts {
        font-size: 14px !important;
        color: #7d7d7d;
        font-family: 'Proxima-regular' !important;
    }

    .order_id_date_text {
        color: #7d7d7d;
        font-family: 'Proxima-regular' !important;
        font-size: 14px !important;
    }

    .order_summary_containers {
        background-color: white;
        border-radius: 10px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .order_status_text {
        font-size: 15px !important;
        line-height: normal !important;
        font-weight: 200 !important;
    }

    .order_status_title {
        font-size: 16px !important;
        line-height: normal !important;
        margin-top: 0;
    }

    .summary_total_container {
        background-color: white;
        border: 2px solid #3cb37b;
        margin-top: 10px;
        text-align: center;
        padding: 5px;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 390px;
        transform: translateX(-50%);
        left: 50%;
    }

    .item_saving_text {
        color: #c3c3c3;
        font-family: 'Proxima-regular' !important;
        margin: 0;
        font-size: 14px !important;
    }

    .summary_total_text {
        font-family: 'Proxima-regular' !important;
        font-size: 16px !important;
        margin: 0;
    }

    .summary_pd_text {
        font-family: 'Proxima-regular' !important;
        font-weight: 400;
        line-height: normal !important;
        font-size: 14px !important;
    }

    .summary_pd_num {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500;
        font-size: 14px !important;
    }

    .summary_cutout_price {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: 500;
        color: #7d7d7d;
        font-size: 12px !important;
        text-decoration: line-through;
        margin-right: 10px;
    }

}